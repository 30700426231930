<template>
  <div
    class="service-item"
    :class="{ divisor: !$attrs.last }"
    :style="getStyles"
    v-if="service"
  >
    <div class="service-item-icon" :class="getServiceIcon" />
    <div class="service-item-wrapper">
      <div class="service-item-title content-title" v-text="serviceData.name" />
      <div
        class="service-item-subtitle body-small"
        v-text="serviceData.extra"
      />
    </div>
    <Checkbox
      :checked="isChecked"
      type="fullcheck-gray"
      @value="setCheckbox"
      v-if="hasCheckbox"
    />
  </div>
</template>

<script>
import { Checkbox, ServiceIcons } from '@seliaco/red-panda'
export default {
  name: 'ServiceItem',
  components: { Checkbox },
  model: {
    prop: 'isChecked',
    event: 'checked'
  },
  props: {
    service: Object,
    hasCheckbox: Boolean,
    isChecked: Boolean
  },
  data () {
    return {
      ServiceIcons
    }
  },
  methods: {
    setCheckbox ($event) {
      this.$emit('checked', $event.isChecked)
    }
  },
  computed: {
    serviceData: {
      get () {
        const { service } = this.service || null

        const duration = `${service.duration} ${this.$translations.time.minutes}`

        return {
          name: service.name,
          extra: duration,
          hasFaceToFace: Boolean(this.service.kalendme_in_person_link_id)
        }
      }
    },
    getServiceIcon () {
      return this.ServiceIcons[this.service.service.icon_name].iconName
    },
    getStyles () {
      const config = this.ServiceIcons[this.service.service.icon_name]
      return {
        '--item-background': config.background,
        '--item-color': config.color
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.service-item
  display: grid
  grid-template-columns: 40px 1fr min-content
  gap: 8px
  padding: 17px 0
  &-wrapper
    display: flex
    flex-direction: column
    justify-content: space-between
  &-icon
    display: flex
    justify-content: center
    align-items: center
    background: var(--item-background)
    color: var(--item-color)
    padding: 10px
    border-radius: 50%
    height: 40px

.divisor
  border-bottom: 1px solid var(--gray-20)
</style>
