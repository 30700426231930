<template>
  <PageContainer
    :title="$translations.profile.service.title"
    :back="actions.close"
    :submit="actions.submit"
    :has-shy-header="true"
  >
    <template v-slot:content>
      <template v-if="loading">
        <div class="page-segment">
          <div class="page-segment-content">
            <Skeleton width="100px" height="20px" border-radius="16px" />
            <div class="services-list-skeleton">
              <Skeleton width="100%" height="70px" border-radius="16px" />
              <Skeleton width="100%" height="70px" border-radius="16px" />
            </div>
          </div>
        </div>
        <div class="page-segment">
          <div class="page-segment-content">
            <Skeleton width="100%" height="20px" border-radius="16px" />
          </div>
        </div>
      </template>
      <template v-if="!loading && services">
        <div class="page-segment">
          <div class="page-segment-content">
            <Heading
              class="margin-bottom"
              :text="$translations.profile.service['virtual-text']"
            />
            <ServiceItem
              :service="service"
              :last="index === services.length - 1"
              v-for="(service, index) of services"
              :key="index"
            />
          </div>
        </div>
        <div class="page-segment">
          <div class="page-segment-content">
            <Heading
              :class="{ 'margin-bottom': hasInPersonAppointment }"
              :text="$translations.profile.service['face-to-face-text']"
              :action="handlerAction"
            />
            <template v-if="hasInPersonAppointment">
              <ServiceItem
                v-model="form[index].value"
                :last="index === services.length - 1"
                :service="service"
                :has-checkbox="true"
                :is-checked="form[index].value"
                @checked="handlerForm(form[index])"
                v-for="(service, index) of services"
                :key="index"
              />
            </template>
          </div>
        </div>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  Heading,
  SpecialistsServices,
  Skeleton
} from '@seliaco/red-panda'
import ServiceItem from '../components/schedule/ServiceItem.vue'
import { ServiceActionsEnum } from '../enums/services.actions'
export default {
  name: 'ServicesList',
  components: { PageContainer, Heading, ServiceItem, Skeleton },
  data () {
    return {
      loading: true,
      actions: {
        close: {
          callback: () => this.goBack()
        },
        submit: {
          text: this.$translations.general.save,
          callback: () => this.update(),
          loading: false,
          disabled: true
        }
      },
      hasInPersonAppointment: false,
      form: [],
      formTemp: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const specialistId = this.$store.getters['auth/specialist'].id
      SpecialistsServices.getServicesToSpecialist(specialistId)
        .then((response) => {
          // get services
          this.services = response
          // init form
          for (const item of this.services) {
            this.form.push({
              value: Boolean(item.kalendme_in_person_link_id),
              id: item.service.specialist_service_id
            })
            if (item.kalendme_in_person_link_id) {
              this.hasInPersonAppointment = true
            }
          }

          if (this.hasInPersonAppointment) {
            this.formTemp = JSON.parse(JSON.stringify(this.form))
          }
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error['specialist-service']
          })
        })
        .finally(() => (this.loading = false))
    },
    handlerForm (val) {
      this.actions.submit.disabled = false
    },
    changeAction () {
      this.formTemp = []
      this.actions.submit.disabled = true
      this.hasInPersonAppointment = !this.hasInPersonAppointment

      if (this.hasInPersonAppointment) {
        for (const item of this.form) {
          item.value = true
        }
        this.formTemp = JSON.parse(JSON.stringify(this.form))
        this.update()
      } else {
        this.update(true)
      }
    },
    update (desactivate = false) {
      this.actions.submit.loading = true
      const body = []
      for (const item of this.form) {
        if (desactivate) {
          body.push({
            service_id: item.id,
            in_person_link: ServiceActionsEnum.DELETE
          })
        } else if (item.value) {
          body.push({
            service_id: item.id,
            in_person_link: ServiceActionsEnum.CREATE
          })
        } else {
          body.push({
            service_id: item.id,
            in_person_link: ServiceActionsEnum.DELETE
          })
        }
      }

      SpecialistsServices.updateService(body)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success['services-update']
          })
        })
        .catch((e) =>
          this.$toast({
            severity: 'error',
            text: this.$translations.error['specialist-service-update']
          })
        )
        .finally(() => {
          this.actions.submit.loading = false
          this.actions.submit.disabled = true
        })
    },
    goBack () {
      if (this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.$router.push({ name: 'Profile', query: { back: this.$route.fullPath } })
      }
    }
  },
  computed: {
    handlerAction () {
      const action = this.hasInPersonAppointment ? 'desactivate' : 'activate'
      return {
        text: this.$translations.profile.service.actions[action],
        callback: () => this.changeAction()
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.services-list-skeleton
  display: flex
  flex-direction: column
  gap: 8px
  margin-top: 16px
</style>
